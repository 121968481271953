import React, { Suspense, lazy } from "react";
import Layout from "../components/Layout";
import { Typography, Box, Grid } from "@mui/material";

const photos = [
  "/images/photo1.jpg",
  "/images/photo2.jpg",
  "/images/photo3.jpg",
  "/images/photo4.jpg",
  "/images/photo5.jpg",
  "/images/photo6.jpg",
  "/images/photo8.jpg",
  "/images/photo9.jpg",
  "/images/photo10.jpg",
  "/images/photo11.jpg",
  "/images/photo12.jpg",
  "/images/photo13.jpg",
];

const ImageComponent = lazy(() => import("../components/Images.tsx"));

export default function Gallery() {
  return (
    <Layout>
      <Box sx={{ p: 4, mt: 5 }}>
        <Typography variant="h4" gutterBottom>
          Galeri
        </Typography>
        <Grid container spacing={2}>
          {photos.map((photo, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Suspense fallback={<div>Loading...</div>}>
                <ImageComponent src={photo} alt={`Gallery photo ${index + 1}`} />
              </Suspense>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Layout>
  );
}
