import React from "react";
import { Box, Typography, Grid, Link as MuiLink } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function WhyUs() {
  return (
    <Box sx={{ padding: 4 }}>
      <Typography sx={{ fontWeight: "600" }} variant="h4" gutterBottom>
        Neden Biz?
      </Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <img
            src="/images/why-us.jpg"
            alt="Antalyam Antrepo"
            style={{ width: "100%", borderRadius: "8px" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="overline" display="block" gutterBottom>
            Kurumsal Sürdürülebilirlik
          </Typography>
          <Typography variant="h5" gutterBottom>
            Sürdürülebilir Lojistiğin Geleceği
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Yüksek kaliteli hizmet anlayışı, rekabetçi fiyatlar ve müşteri odaklı yaklaşımımızla
            sizlere en iyi depolama çözümlerini sunuyoruz. Antrepomuz, işletmenizin büyümesine katkı
            sağlamak için ideal bir ortam sunar.
          </Typography>
          <MuiLink
            component={RouterLink}
            to="/about"
            variant="body1"
            color="primary"
            sx={{ fontWeight: "bold", cursor: "pointer", textDecoration: "none" }}>
            Daha Detaylı Bilgi İçin {">"}
          </MuiLink>
        </Grid>
      </Grid>
    </Box>
  );
}
