import React from "react";
import Header from "./Header.tsx";
import Footer from "./Footer";
import { Container, Box } from "@mui/material";

export default function Layout({ children }) {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <Container component="main" sx={{ mt: 8, mb: 4, flexGrow: 1 }}>
        {children}
      </Container>
      <Footer />
    </Box>
  );
}
