import React from "react";
import Layout from "../components/Layout";
import { Typography, Box, Paper } from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Link } from "react-router-dom";
import L from "leaflet";

const position = [36.89045715332031, 30.60663414001465];

const customIcon = new L.Icon({
  iconUrl: "/images/LOGO_antalyam.png",
  iconSize: [40, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export default function Contact() {
  const handleMapClick = () => {
    window.open(
      "https://www.google.com/maps?q=36.89045715332031,30.60663414001465&z=17&hl=tr",
      "_blank"
    );
  };

  return (
    <Layout>
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Bizimle İletişime Geçin
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          <strong>WhatsApp No: </strong>
          <Link to={"https://wa.me/905064660700"} target="_blank">
            0506 466 0700
          </Link>
        </Typography>
        <Typography variant="body1">
          <strong>Sabit No: </strong>
          <Link to={"tel:02423227700"} target="_blank">
            0242 322 7700
          </Link>
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
          Antrepomuz ile ilgili detaylı bilgi almak ve ihtiyaçlarınıza uygun çözümleri keşfetmek
          için bizimle iletişime geçmekten çekinmeyin. Profesyonel ekibimiz size yardımcı olmaktan
          memnuniyet duyacaktır.
        </Typography>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Ziyaret Edin
          </Typography>
          <div style={{ height: "300px", width: "100%" }} onClick={handleMapClick}>
            <MapContainer center={position} zoom={15} style={{ height: "100%", width: "100%" }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={position} icon={customIcon}>
                <Popup>Bizim Konumumuz</Popup>
              </Marker>
            </MapContainer>
          </div>
        </Paper>
      </Box>
    </Layout>
  );
}
