import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Fab,
  useScrollTrigger,
  Slide,
  CssBaseline,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

interface Props {
  window?: () => Window;
  children?: React.ReactElement;
}

function ScrollTop(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    const anchor = (window ? window().document : document).documentElement;
    if (anchor) {
      anchor.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <Slide direction="up" in={trigger}>
      <Box onClick={handleClick} sx={{ position: "fixed", bottom: 16, right: 16 }}>
        {children}
      </Box>
    </Slide>
  );
}

export default function Header(props: Props) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const menuItems = [
    { text: "Ana Sayfa", link: "/" },
    { text: "Hakkımızda", link: "/about" },
    { text: "Hizmetlerimiz", link: "/services" },
    { text: "Galeri", link: "/gallery" },
    { text: "Blog", link: "/blog" },
    { text: "İletişim", link: "/contact" },
  ];

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          background: trigger ? "rgba(255, 255, 255, 0.9)" : "transparent",
          color: "black",
          zIndex: 900,
          boxShadow: trigger ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none",
          transition: "background 0.1s, box-shadow 0.3s",
          padding: "16px",
        }}>
        <Container maxWidth="lg">
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Link to="/" style={{ alignItems: "center" }}>
                <img
                  src="/images/LOGO_antalyam.png"
                  alt="Antalyam Antrepo"
                  style={{ height: "60px", marginRight: "20px" }}
                />
              </Link>
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex" }, gap: 3 }}>
              {menuItems.map((item) => (
                <Button
                  sx={{
                    fontWeight: "bold",
                    color: isHomePage ? "inherit" : "black",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                    },
                  }}
                  key={item.text}
                  component={Link}
                  to={item.link}>
                  {item.text}
                </Button>
              ))}
            </Box>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ display: { xs: "flex", md: "none" } }}
              onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer anchor="top" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: "auto",
            paddingTop: 2,
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}>
          <Link to="/" style={{ alignItems: "center" }}>
            <img
              src="/images/LOGO_antalyam.png"
              alt="Antalyam Antrepo"
              style={{ height: "60px", marginBottom: "20px" }}
            />
          </Link>
          <Divider sx={{ width: "100%" }} />
          <List sx={{ width: "100%" }}>
            {menuItems.map((item) => (
              <ListItem button key={item.text} component={Link} to={item.link}>
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{ align: "center", fontWeight: "bold" }}
                />
              </ListItem>
            ))}
          </List>
          <Box sx={{ marginBottom: 2 }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="whatsapp"
              component="a"
              href="https://wa.me/905064660700"
              target="_blank">
              <WhatsAppIcon fontSize="large" />
            </IconButton>
          </Box>
        </Box>
      </Drawer>
      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}
