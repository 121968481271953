import React, { useEffect, useRef } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import './VideoSection.css'

export default function VideoSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const videoSrc = isMobile ? "/videos/MobileVideo.mp4" : "/videos/antalyamHome.mp4";
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    if (isMobile && video) {
      const playVideo = async () => {
        try {
          await video.play();
        } catch (err) {
          console.log("Auto-play was prevented, showing a play button instead.");
        }
      };
      playVideo();
    }
  }, [isMobile]);

  return (
    <Box
    className={"video-section"}
      sx={{
        position: "relative",
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#000",
      }}>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}>
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "contain",
            background: "white"
          }}>
          <source src={videoSrc} type="video/mp4" />
        </video>
      </div>
    </Box>
  );
}
