import React from "react";
import Layout from "../components/Layout";
import VideoSection from "../components/VideoSection";
import About from "../components/About";
import { Box } from "@mui/material";
import "../styles/Home.module.css";
import WhyUs from "../components/WhyUs.tsx";

export default function Home() {
  return (
    <>
      <VideoSection />
      <Layout>
        <Box sx={{ mt: 4 }}>
          <WhyUs />
        </Box>
        <Box sx={{ mt: 4 }}>
          <About />
        </Box>
      </Layout>
    </>
  );
}
