import React from "react";
import Layout from "../components/Layout";
import { Typography, Box, Paper } from "@mui/material";
import { styled } from "@mui/system";
import Carousel from "react-material-ui-carousel";

const AboutContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  overflow: "hidden",
  "& img": {
    width: "100%",
    height: "400px", // Slider yüksekliğini artırdık
    objectFit: "cover",
  },
}));

const InfoSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(4),
  // textAlign: "center",
  backgroundColor: theme.palette.background.default,
}));

const images = [
  { src: "/images/photo2.jpg", alt: "Slider 1" },
  { src: "/images/photo6.jpg", alt: "Slider 2" },
  { src: "/images/photo11.jpg", alt: "Slider 3" },
];

export default function About() {
  return (
    <Layout>
      <AboutContainer>
        <Typography variant="h3" align="center" gutterBottom>
          Hakkımızda
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
          Antalyam Antrepo Lojistik Geri Dönüşüm San. Ve Tic. A.Ş. olarak stratejik konumumuzla,
          Antalya Limanı'na sadece 10 kilometre mesafede bulunmasıyla, lojistik operasyonların hızlı
          ve verimli bir şekilde yönetilmesini sağlar. Limana yakınlığıyla, müşterilerimizin taşıma
          ve dağıtım süreçlerini optimize etmelerine de olanak sağlamış bulunmaktayız. 5000m2
          gümrüklü, 5000m2 gümrüksüz, 7000m2 açık alana sahip Yeni nesil antrepomuz, depolama
          çözümlerinde kalite ve güvenilirliği bir araya getiriyor. Modern tesisimiz, işletmenizin
          depolama ihtiyaçlarını karşılamak için özel olarak tasarlandı. Sektördeki deneyimimizle,
          müşteri memnuniyetini en üst düzeye çıkarmak için buradayız. Antalya’nın İlk -18/+5 Derece
          Soğuk Hava Depolama hizmetiniz sunmaktayız. En hassas ürünleriniz için ideal depolama
          sıcaklıklarını sağlıyoruz. Gıda, ilaç ve diğer hassas ürünlerinizi güvenle
          depolayabilirsiniz.
        </Typography>

        <Box sx={{ maxWidth: "1000px", mx: "auto", mb: 4 }}>
          <Carousel>
            {images.map((item, index) => (
              <ImageContainer key={index}>
                <img src={item.src} alt={item.alt} loading="lazy" />
              </ImageContainer>
            ))}
          </Carousel>
        </Box>

        <InfoSection elevation={3}>
          <Typography variant="h4" gutterBottom align="center">
            Vizyonumuz
          </Typography>
          <Typography variant="body1" paragraph>
            Antrepo sektöründe öncü bir firma olarak, müşterilerimizin lojistik ve depolama
            ihtiyaçlarını karşılamak için sürekli olarak yenilikçi ve verimli çözümler sunmak.
            Müşteri odaklı yaklaşımımızla sektördeki lider konumumuza devam ederken, teknolojiyi
            etkili bir şekilde kullanarak sürdürülebilir büyümeyi hedeflemekteyiz. Ayrıca,
            çalışanlarımızın gelişimine yatırım yaparak onların memnuniyetini sağlayarak başarılı
            bir ekip oluşturmayı amaçlıyoruz.
          </Typography>
          <Typography variant="h4" gutterBottom align="center" sx={{marginTop:"5px"}}>
            Misyonumuz
          </Typography>
          <Typography variant="body1" paragraph>
            Güvenilirlik, kalite ve müşteri memnuniyetini ön planda tutarak, depolama ve lojistik
            hizmetlerinde en iyi çözümleri sunmak. Aynı zamanda sürdürülebilirlik ilkelerine bağlı
            kalarak çevreye duyarlı bir şekilde çalışmak.
          </Typography>
        </InfoSection>
      </AboutContainer>
    </Layout>
  );
}
