import React from "react";
import { Box, Typography, IconButton, Grid, Container } from "@mui/material";
import { Link } from "react-router-dom";
import { WhatsApp } from "@mui/icons-material";

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: "#f9f9f9",
        color: "white",
        p: 2,
        textAlign: "center",
      }}>
      <Container maxWidth="lg">
        <Link to="/" className="flex justify-center mb-5">
          <img src="/images/LOGO_antalyam.png" alt="Antalyam Antrepo" style={{ height: "80px" }} />
        </Link>
        <Grid container spacing={4} sx={{ marginBottom: "30px" }}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h5" color="black" gutterBottom>
              Bloglar
            </Typography>
            <Box>
              <Link to="/">
                <Typography variant="body2" sx={{ color: "black" }}>
                  Biz Kimiz?
                </Typography>
              </Link>
              <Link to="/">
                <Typography variant="body2" sx={{ color: "black" }}>
                  Misyonumuz
                </Typography>
              </Link>
              <Link to="/">
                <Typography variant="body2" sx={{ color: "black" }}>
                  Neden Biz?
                </Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h5" color="black" gutterBottom>
              Kısayollar
            </Typography>
            <Box>
              <Link to="/about">
                <Typography variant="body2" sx={{ color: "black" }}>
                  Hakkımızda
                </Typography>
              </Link>
              <Link to="/services">
                <Typography variant="body2" sx={{ color: "black" }}>
                  Hizmetlerimiz
                </Typography>
              </Link>
              <Link to="/gallery">
                <Typography variant="body2" sx={{ color: "black" }}>
                  Galeri
                </Typography>
              </Link>
              <Link to="/blog">
                <Typography variant="body2" sx={{ color: "black" }}>
                  Blog
                </Typography>
              </Link>
              <Link to="/contact">
                <Typography variant="body2" sx={{ color: "black" }}>
                  İletişim
                </Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Typography variant="h5" color="black" gutterBottom>
                İletişime Geçin
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                <IconButton
                  href="https://wa.me/905064660700"
                  target="_blank"
                  color="black"
                  sx={{ fontSize: "16px" }}>
                  <WhatsApp />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Typography variant="body2" sx={{ color: "black", fontWeight: "600" }}>
          &copy; 2024 Antalyam Antrepo Lojistik Geri Dönüşüm San. Ve Tic. A.Ş.
        </Typography>
      </Container>
    </Box>
  );
}
