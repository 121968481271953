import React from "react";
import Layout from "../components/Layout";
import { Typography, Box, Paper, Divider } from "@mui/material";
import { styled } from "@mui/system";

const BlogContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.default,
  minHeight: "100vh",
  maxWidth: "800px",
  margin: "0 auto",
}));

const BlogPost = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const BlogImage = styled("img")(({ theme }) => ({
  width: "100%",
  maxHeight: "500px",
  height: "auto",
  objectFit: "cover",
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

// const ReadMoreButton = styled(Button)(({ theme }) => ({
//   marginTop: theme.spacing(2),
//   backgroundColor: theme.palette.primary.main,
//   color: theme.palette.common.white,
//   "&:hover": {
//     backgroundColor: theme.palette.primary.dark,
//   },
// }));

const posts = [
  {
    title: "E-ticaret Patlamasının Antrepo Taleplerine Etkisi",
    content: `
      Son yıllarda e-ticaret sektöründeki patlama, tüketici alışkanlıklarını kökten değiştirdi. Bu durum, antrepoların işlevselliği ve stratejik konumlanması açısından yeni taleplere neden oldu. Tüketicilerin hızlı teslimat beklentisi, stok yönetimi ve depo erişilebilirliği gibi unsurları da ön plana çıkardı.
      
      Bu bağlamda, e-ticaretle gelen taleplerin antrepo operasyonlarına etkisini incelerken öncelikle lojistik sektöründeki zorluklara odaklanmak gerekiyor. E-ticaret patlamasıyla birlikte artan talep, klasik depolama ve lojistik sistemlerinde zorlayıcı durumlar yarattı. Özellikle hızlı teslimat beklentisi karşısında stokların daha hızlı ulaşılabilir olması gerekliliği ortaya çıktı.
      
      Stratejiler topluca başa çıkma için farklı yaklaşımlar geliştirilmelidir. Esnek depolama çözüm modellerinin kullanımı önem taşımaktadır; mevcut stoğun daha iyi organize edilmesiyle birlikte yeni gelen ürünler için esnek alan yaratılabilir. Ayrıca çoklu stok noktalarında avantajlar sağlayarak coğrafi dağılıma göre farklı bölgelerde birden çok merkezi olanak bulunmalıdır.
      
      Talebe göre ölçeklenebilir depo sistemleri de dikkate alınmalıdır; bu sayede depodaki stok miktarını kontrol etmek kolaylaşacak ve lojistik süreçlerin verimliliği artacaktır. Son olarak, e-ticaretin yükselişiyle oluşan talep patlamasına karşı strateji belirlenmeli ve bunun antrepoda nasıl değer oluşturabileceği üzerine odaklanılmalıdır. Bu stratejiler doğrultusunda e-ticaret patlamasının antrepo taleplerine etkisini en iyi şekilde değerlendirmek mümkün olacaktır.
    `,
    imageUrl: "/images/e-commerce-1.jpg",
  },
];

export default function Blog() {
  return (
    <Layout>
      <BlogContainer>
        {posts.map((post, index) => (
          <React.Fragment key={index}>
            <BlogPost elevation={3}>
              <BlogImage src={post.imageUrl} alt={post.title} />
              <Typography variant="h5" gutterBottom>
                {post.title}
              </Typography>
              <Typography variant="body1" paragraph>
                {post.content.split("\n").map((str, index) => (
                  <React.Fragment key={index}>
                    {str.trim()}
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
              {/* <ReadMoreButton variant="contained">Devamını Oku</ReadMoreButton> */}
            </BlogPost>
            {index < posts.length - 1 && <Divider sx={{ mb: 4 }} />}
          </React.Fragment>
        ))}
      </BlogContainer>
    </Layout>
  );
}
