import React, { Suspense } from "react";
import Layout from "../components/Layout";
import { Typography, Box, Grid, Card, CardContent, CardMedia, Button } from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";

const services = [
  {
    title: "Depolama Çözümleri",
    description:
      "Her tür eşya ve malzeme için özelleştirilebilir depolama seçenekleri. Uzman kadromuz ve modern tesislerimizle güvenli ve verimli depolama hizmeti sunuyoruz.",
    image: "/images/storage.jpg",
  },
  {
    title: "Lojistik Destek",
    description:
      "Taşımacılık, ambalajlama ve dağıtım konularında profesyonel hizmetler. Müşterilerimizin lojistik süreçlerini optimize ederek maliyetlerini düşürüyoruz.",
    image: "/images/logistic.jpg",
  },
  {
    title: "Güvenlik",
    description:
      "Gelişmiş güvenlik sistemleri ve 7/24 izleme ile eşyalarınızı güvence altına alıyoruz. Her zaman en üst düzeyde güvenlik ve kontrol sağlıyoruz.",
    image: "/images/security.jpg",
  },
  {
    title: "Elle İşleme Paketleme",
    description:
      "Ürünlerinizi özenle işliyor, elle paketleme hizmeti sunuyoruz. Ürünlerinizi güvenle paketleyip, müşterilerinize en iyi şekilde ulaştırıyoruz.",
    image: "/images/wrapping.jpg",
  },
  {
    title: "Soğuk Hava Hizmetleri",
    description:
      "Antalya’nın İlk -18/+5 Derece Soğuk Hava Depolama hizmetiniz sunmaktayız. En hassas ürünleriniz için ideal depolama sıcaklıklarını sağlıyoruz. Gıda, ilaç ve diğer hassas ürünlerinizi güvenle depolayabilirsiniz.",
    image: "/images/coldAirStorage.jpg",
  },
];

const StyledCard = styled(Card)(({ theme }) => ({
  transition: "transform 0.3s, box-shadow 0.3s",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: theme.shadows[4],
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "400px",
}));

const CardMediaContainer = styled(CardMedia)({
  height: 200,
});

const CardWrapper = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

const StyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  flex: "1 0 auto",
});

export default function Services() {
  return (
    <Layout>
      <Box sx={{ p: 4, backgroundColor: "#f9f9f9" }}>
        <Typography variant="h3" align="center" gutterBottom>
          Hizmetlerimiz
        </Typography>
        <Typography variant="body1" align="center" sx={{ mb: 4 }}>
          Uzman kadromuz ve modern tesislerimizle sunduğumuz çeşitli hizmetler ile müşterilerimizin
          her türlü ihtiyacını karşılıyoruz.
        </Typography>
        <Grid container spacing={4}>
          {services.map((service, index) => (
            <CardWrapper item xs={12} sm={6} md={4} key={index}>
              <Suspense fallback={<div>Loading...</div>}>
                <StyledCard>
                  <CardMediaContainer
                    component="img"
                    image={service.image}
                    alt={service.title}
                    loading="lazy"
                  />
                  <StyledCardContent>
                    <Typography variant="h5" gutterBottom>
                      {service.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {service.description}
                    </Typography>
                  </StyledCardContent>
                </StyledCard>
              </Suspense>
            </CardWrapper>
          ))}
        </Grid>
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <Link to="/contact" style={{ textDecoration: "none" }}>
            <Button variant="contained" color="primary" size="large">
              Daha Fazla Bilgi
            </Button>
          </Link>
        </Box>
      </Box>
    </Layout>
  );
}
