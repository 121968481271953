import React from "react";
import Typography from "@mui/material/Typography";
import { Card, Grid, Box, CardActionArea } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { styled } from "@mui/system";

const StyledCard = styled(Card)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  transition: "transform 0.3s, box-shadow 0.3s",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: theme.shadows[4],
  },
  "& .info-content": {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "22vw",
    overflow: "hidden",
    transition: "height 0.3s, background-color 0.3s, color 0.3s",
    padding: theme.spacing(2),
    boxSizing: "border-box",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
  },
  "@media (min-width: 420px) and (max-width: 768px)": {
    "& .info-content": {
      height: "19vw",
    }
  },
  "@media (min-width: 769px) and (max-width: 1000px)": {
    "& .info-content": {
      height: "11vw",
    }
  },
  "@media (min-width: 1001px) and (max-width: 1400px)": {
    "& .info-content": {
      height: "5.5vw",
    },
    "&:hover .info-content": {
      height: "12.5vw",
    },
  },
  "@media (min-width: 1401px)": {
    "& .info-content": {
      height: "65px",
    },
    "&:hover .info-content": {
      height: "140px",
    },
  },
}));

const InfoContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  boxSizing: "border-box",
  "& .title": {
    fontSize: "33px",
  },
  "& .description": {
    fontSize: "0.8vw",
  },
  "@media (max-width: 768px)": {
    "& .title": {
      fontSize: "16px",
    },
    "& .description": {
      fontSize: "12px",
    },
  },
  "@media (min-width: 769px) and (max-width: 1400px)": {
    "& .title": {
      fontSize: "2.4vw",
    },
    "& .description": {
      fontSize: "1.2vw",
    },
  },
}));

export default function About() {
  const services = [
    {
      title: "Depolama Çözümleri",
      description: "Her tür eşya ve malzeme için özelleştirilebilir depolama seçenekleri",
      image: "/images/storage.jpg",
    },
    {
      title: "Lojistik Destek",
      description: "Taşımacılık, ambalajlama ve dağıtım konularında profesyonel hizmetler",
      image: "/images/logistic.jpg",
    },
    {
      title: "Güvenlik",
      description:
        "Gelişmiş güvenlik sistemleri ve 7/24 izleme ile eşyalarınızı güvence altına alıyoruz",
      image: "/images/security.jpg",
    },
    {
      title: "Elle İşleme Paketleme",
      description: "Ürünlerinizi özenle işliyor, elle paketleme hizmeti sunuyoruz",
      image: "/images/wrapping.jpg",
    },
    {
      title: "Soğuk Hava Hizmetleri",
      description: "Antalya’nın İlk -18/+5 Derece Soğuk Hava Depolama hizmetiniz sunmaktayız",
      image: "/images/coldAirStorage.jpg",
    },
  ];

  return (
    <Box sx={{ p: 4 }}>
      <Typography sx={{ fontWeight: "600"}} variant="h4" gutterBottom>
        HİZMETLERİMİZ
      </Typography>
      <Typography variant="body1" paragraph>
        Uzman kadromuz ve yıllara dayanan tecrübelerimizle sunduğumuz her
        hizmetin kusursuz olması için çalışıyoruz.
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {services.map((service, index) => (
          <Grid item key={index} xs={12} sm={6} md={6}>
            <CardActionArea>
              <StyledCard>
                <CardMedia
                  component="img"
                  height="800"
                  image={service.image}
                  alt={service.title}
                />
                <InfoContent className="info-content">
                  <Typography gutterBottom className="title" component="div">
                    {service.title}
                  </Typography>
                  <Typography className="description">
                    {service.description}
                  </Typography>
                </InfoContent>
              </StyledCard>
            </CardActionArea>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
